import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image';

/**
 * Use Gatsby images both in pages and previews.
 *
 * @param {*} param0
 */
const PreviewCompatibleImage = ({ imageInfo }) => {
  const { alt = '', childImageSharp, image } = imageInfo;

  if (childImageSharp) {
    return <Img { ...childImageSharp } alt={ alt } />;
  }

  if (image) {
    if (image.childImageSharp) {
      return (
        <Img { ...image.childImageSharp } alt={ alt } />
      );
    }

    if (typeof image === 'string') {
      return <img src={ image } alt={ alt } />;
    }
  }

  return null;
};


PreviewCompatibleImage.propTypes = {
  imageInfo: PropTypes.shape({
    alt: PropTypes.string,
    childImageSharp: PropTypes.object,
    image: PropTypes.oneOfType([ PropTypes.object, PropTypes.string ]),
    style: PropTypes.object,
  }).isRequired,
};

export default PreviewCompatibleImage;
