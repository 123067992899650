/* eslint-disable react/no-danger */
import React from 'react';
import { graphql } from 'gatsby';

import PageWrapper from 'Layouts/PageWrapper';

import PreviewCompatibleImage from 'Components/cms/PreviewCompatibleImage';
import SEO from 'Components/SEO';

export const PersonTemplate = ({
  name,
  image,
  content,
}) => (
  <>
    <div className="page__hero page__hero--person">
      <h1 className="page__hero__title">{ name }</h1>
      <PreviewCompatibleImage imageInfo={ image } />
    </div>

    <div className="page__content" dangerouslySetInnerHTML={ { __html: content } } />
  </>
);

const Person = ({ data }) => {
  const { markdownRemark: team } = data;

  return (
    <PageWrapper>
      <SEO
        description={ team.excerpt }
        title={ team.frontmatter.name }
      />
      <PersonTemplate
        name={ team.frontmatter.name }
        image={ team.frontmatter.image }
        content={ team.html }
      />
    </PageWrapper>
  );
};

export default Person;

export const query = graphql`
  query($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      excerpt
      frontmatter {
        name
        position
        telephone
        email
        current
        image {
          childImageSharp {
            fixed(width: 140) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`;
